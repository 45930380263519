// Import our CSS
import styles from '../css/app.pcss';


// Core JS file
import * as PhotoSwipe from 'photoswipe';
// UI JS file
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', vh + 'px');

// We listen to the resize event
window.addEventListener('resize', function(){
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
});


var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        //var thumbElements = el.childNodes,
        var thumbElements = document.querySelectorAll('figure[rel="lightbox"]'),
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;
    
        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };

            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML; 
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            } 

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };


    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {

        e = e || window.event;
        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }
        if(clickedListItem.getAttribute('rel') != 'lightbox') {
            return;
        }
        // prevent click now we know it's lightbox
        e.preventDefault();
        e.preventDefault ? e.preventDefault() : e.returnValue = false;



        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        // var clickedGallery = clickedListItem.parentNode,
        //     childNodes = clickedListItem.parentNode.childNodes,
        //     numChildNodes = childNodes.length,
        //     nodeIndex = 0,
        //     index;
        var clickedGallery = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'ARTICLE');
        });

        // for (var i = 0; i < numChildNodes; i++) {
        //     if(childNodes[i].nodeType !== 1) { 
        //         continue; 
        //     }

        //     if(childNodes[i] === clickedListItem) {
        //         index = nodeIndex;
        //         break;
        //     }
        //     nodeIndex++;
        // }
        var index = clickedListItem.getAttribute('data-index');

        if(index >= 0 && index != null) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');  
            if(pair.length < 2) {
                continue;
            }           
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);


        // define options (if needed)
        options = {

            shareEl: false,

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };



    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};


// App main
// const main = async () => {
//     // Async load the vue module
//     const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
//     // Create our vue instance
//     const vm = new Vue({
//         el: "#dropdown",
//         components: { },
//         data: {
//             isOpen: false
//         },
//         methods: {
//         },
//         mounted() {
//         },
//     });

//     return vm;
// };

// Execute async function
// main().then( (vm) => {
//     initPhotoSwipeFromDOM('article');
// });

import Vue from 'vue';

document.addEventListener("DOMContentLoaded", function() { 

    new Vue({
        el: "#dropdown",
        data: {
            isOpen: false
        }
    });

    initPhotoSwipeFromDOM('article');
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
